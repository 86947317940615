<template>
  <div class="card">
    <div class="card-body">
      <h4>Change Profile</h4>

      <div class="form-group">
        <label for="oldPassword">Name</label>
        <input type="text" v-model="name" class="form-control">
      </div>

      <div class="form-group">
        <label for="oldPassword">Email</label>
        <input type="text" v-model="email" disabled class="form-control">
      </div>

      <div class="form-group">
        <label for="oldPassword">Profile Picture</label>
        <CustomImagePicker
          :image_data1="image_data"
          @onResponse="onImageResponse"/>
      </div>

      <div class="form-group">
        <label for="oldPassword">Phone</label>
        <div class="input-group mb-3">
          <div class="input-group-prepend">
            <span class="input-group-text" id="basic-addon1">+62</span>
          </div>
          <input type="text" v-model="phone_number" class="form-control">
        </div>
      </div>

      <div class="form-group">
        <label for="newPassword">Address</label>
        <textarea v-model="address" class="form-control mb-3"></textarea>
      </div>
			
			<div class="form-group">
				<label for="confirmPassword">{{ $t('country') }}</label>
				<select v-model="country" class="form-control mb-3">
					<option value="">{{ $t('select_country') }}</option>
					<option v-for="(country, index) in arr_country" :key="index" :value="country.id">{{ country.name }}</option>
					<option value="other">{{ $t('other') }}</option>
				</select>
			</div>
			
			<div class="form-group" v-if="country != '' && country == 'other'">
				<label for="confirmPassword">{{ $t('name_country') }}</label>
				<input type="text" v-model="name_other_country" class="form-control">
			</div>

      <div class="form-group" v-if="arr_province.length > 0">
        <label for="confirmPassword">Province</label>
        <select v-model="province" class="form-control mb-3">
          <option value="">{{ $t('select_province') }}</option>
          <option v-for="(province, index) in arr_province" :key="index" :value="province.id">{{ province.name }}</option>
        </select>
      </div>

      <div class="form-group" v-if="arr_city.length > 0">
        <label for="confirmPassword">City</label>
        <select v-model="city" class="form-control mb-3">
          <option value="">{{ $t('select_city') }}</option>
          <option v-for="(city, index) in arr_city" :key="index" :value="city.id">{{ city.name }}</option>
        </select>
      </div>

      <div>
        <button class="btn btn-outline-primary" @click="onBackClicked">Back</button>
        <button class="btn btn-primary ml-3" @click="onSubmitClicked">Submit</button>
      </div>
    </div>
  </div>
</template>

<script>
import $ from 'jquery'
import Base from '@/utils/base';

import NoImage from '@/assets/no_image.png';
import CustomImagePicker from '@/layout/custom_imagepicker';

export default {
  components: {
    'CustomImagePicker': CustomImagePicker,
  },
  data() {
    return {
      base: null,
      name: '',
      email: '',
      phone_number: '',
			arr_country: [],
      arr_province: [],
      arr_city: [],
			country: '',
      province: '',
      city: '',
      address: '',
			name_other_country: '',
      image_data: {},
    }
  },
  watch: {
    phone_number(val){
      this.phone_number = this.base.phone_validation(val)
    },
		country(val){
			if(val != '' && val != 'other')
				this.get_province()
		},
    province(val){
      if(val != '')
        this.get_city()
    },
  },
  async created() {
    this.base = new Base()
    this.base.save_current_page('Change Profile', false, 'dripicons-user text-muted')

    this.get_auth()
    this.get_country()
  },
  methods: {
    onBackClicked(){
      this.base.back_page()
    },
    onImageResponse(image_data){
      this.image_data = image_data
    },
		async get_country(){
			var response = await this.base.request(this.base.url_api + "/country/all")
		
			if(response != null){
				if(response.status === "success"){
					this.arr_country = response.data
				}
				else
					this.base.show_error(response.message)
			}
			else
				console.log(this.$t('server_error'))
		},
    async get_province(){
      var response = await this.base.request(this.base.url_api + "/province/all?country_id=" + this.country)

      if(response != null){
        if(response.status === "success"){
          this.arr_province = response.data
        }
        else
          this.base.show_error(response.message)
      }
      else
        console.log(this.$t('server_error'))
    },
    async get_city(){
      var response = await this.base.request(this.base.url_api + "/city/all?province_id=" + this.province)

      if(response != null){
        if(response.status === "success"){
          this.arr_city = response.data
        }
        else
          this.base.show_error(response.message)
      }
      else
        console.log(this.$t('server_error'))
    },
    async get_auth(){
      var response = await this.base.request(this.base.url_api + "/auth/profile")

      if(response != null){
        if(response.status == "success"){
          this.profile_data = response.data
          this.name = response.data.name
          this.email = response.data.email
          this.image_data = {
            image_display: response.data.file_name != null ? this.base.host + '/media/user?file_name=' + response.data.file_name : NoImage,
            image_data: null,
          }
          this.phone_number = response.data.phone.substring(3)
          this.address = response.data.address
          this.country = response.data.country.id
					if(response.data.city != null){
						this.province = response.data.city.province.id
						this.city = response.data.city.id
					}
        }
        else
          this.base.show_error(response.message)
      }
      else
        console.log(this.$t('server_error'))
    },
    async onSubmitClicked(){
      if(this.phone_number == "")
        this.base.show_error(this.$t('phone_number_empty'))
      else if(this.address == "")
        this.base.show_error(this.$t('address_empty'))
			else if(this.country == "")
				this.base.show_error(this.$t('country_empty'))
			else if(this.country == "other" && this.name_other_country == "")
				this.base.show_error(this.$t('name_country_empty'))
      else if(this.province == "")
        this.base.show_error(this.$t('province_empty'))
      else if(this.city == "")
        this.base.show_error(this.$t('city_empty'))
      else{
        var province = {}
        var city = {}
        var data = {}

        for(let province1 of this.arr_province){
          if(province1.id == this.province){
            province = province1
            break
          }
        }
        for(let city1 of this.arr_city){
          if(city1.id == this.city){
            city = city1
            break
          }
        }

        data = {
          name: this.name,
          phone: "+62" + this.phone_number,
          address: this.address,
					country: {
						id: this.country,
					},
					name_other_country: this.name_other_country,
          province: province,
          city: city,
        }

        if(this.image_data.data_image != null)
          data.image = {
            file: this.image_data.data_image,
          }

        $('#please_wait_modal').modal('show')
        var response = await this.base.request(this.base.url_api + "/auth/change-profile", 'post', data)
        $('#please_wait_modal').modal('hide')

        if(response != null){
          if(response.status === "success"){
            this.base.back_page()
          }
          else
            this.base.show_error(response.message)
        }
        else
          console.log(this.$t('server_error'))

        
      }
    },
  }
}
</script>

<style lang="scss">
</style>
