import $ from 'jquery'
import axios from 'axios'

export default class Base{
  landing_host = "https://student-open.com"
  // landing_host = "https://student-open-web.quantumtri.com"
  // landing_host = "http://localhost:8080"
  // host = "https://legoasapi.legoas.co.id"
  host = "https://api.student-open.com"
  // host = "https://student-open-api.quantumtri.com"
  // host = ""
  url_api = this.host + "/api"
  version = ""
  locale_string = "id-ID"
  locale_timezone = "Asia/Jakarta"
  local_currency = "Rp."
  local_area_phone = "+62"
  wait_time = 1500
  wait_datatable_time = 4000
  app_version = '0.2.0057'
  date_format = 'DD-MM-YYYY'
  month_format = 'MM-YYYY'
  min_age = 0
  max_player_team_name = 0

	cutoff_str = 'Cut-Off'
	cutoff_tournament_str = 'Cut-Off + Tournament'

  host2 = "https://lelang.legoas.co.id:8443"
  url_api2 = this.host2 + "/api/portal"
  auth = "Basic ODUwMWRjNjRiOTEzNDcyZmE0Mjc0MTE5MTEzYjdjNGQ6TFNTLWRjYTU0ZDQ0ODFjZDRlYTU4NDAwYzk4NDM3N2EzMzk1"

  constructor(){
    if(window.location.origin == "https://student-open.com"){
      this.host = "https://api.student-open.com"
      this.landing_host = "https://student-open.com"
      this.url_api = this.host + "/api"

			this.cutoff_str = 'Cut-Off'
			this.cutoff_tournament_str = 'Cut-Off & Tournament'
    }
    else if(window.location.origin == "https://student-open-user-admin.quantumtri.com"){
      this.host = "https://student-open-api.quantumtri.com"
      this.landing_host = "https://student-open-web.quantumtri.com"
      this.url_api = this.host + "/api"

			this.cutoff_str = 'Cut-Off'
			this.cutoff_tournament_str = 'Cut-Off + Tournament'
    }
    else if(window.location.origin == "http://localhost:8011"){
      // this.host = "https://api.student-open.com"
      this.host = "https://student-open-api.quantumtri.com"
      this.landing_host = "http://localhost:8010"
      this.url_api = this.host + "/api"

			this.cutoff_str = 'Cut-Off'
			this.cutoff_tournament_str = 'Cut-Off + Tournament'
    }
  }

  set_wait_datatable_time(wait_datatable_time){
    this.wait_datatable_time = wait_datatable_time
  }

  async save_current_page(title, is_reset = false, icon = ''){
    var arr_pages = await window.localStorage.getItem('arr_pages')
    arr_pages = arr_pages != null && !is_reset ? JSON.parse(arr_pages) : []

    var counter = 0
    for(let page of arr_pages){
      if(title == page.name)
        break
      counter++
    }

    if(counter == arr_pages.length)
      arr_pages.push({
        name: title,
        icon: icon,
        url: window.location.href,
      })

    window.localStorage.setItem('arr_pages', JSON.stringify(arr_pages))
  }

  async back_page(with_redirect = true, how_many = 1){
    var arr_pages = await window.localStorage.getItem('arr_pages')
    arr_pages = JSON.parse(arr_pages)

    if(how_many > 0){
      for(let x = 1; x <= how_many; x++)
        arr_pages.pop()
    }
    else
      arr_pages = [arr_pages[0]]
    var obj = arr_pages[arr_pages.length - 1]

    window.localStorage.setItem('arr_pages', JSON.stringify(arr_pages))

    if(with_redirect)
      window.location.href = obj.url
  }

  async init_datatable(datatable, element, url, arr_order, arr_column, arr_column_def, arr_button = [], onCompleteAjax = () => {}){
    if(datatable != null)
    //   datatable.destroy()
      window.$(element).DataTable().clear().destroy();
    // console.log(datatable)
    var context = this
    var token = await window.localStorage.getItem('token')
    datatable = window.$(element).DataTable({
      "processing" : true,
      "serverSide" : true,
      bLengthChange: false,
      fixedColumns: true,
      bAutoWidth: false,
      responsive: true,
      scrollX: true,
      dom: 'Bfrtip',
      buttons: arr_button,
      language: {
        searchPlaceholder: 'Search...',
        sSearch: '',
      },
      "ajax" : {
        url : url,
        type : "GET",
        dataType : "json",
        headers : {
          "content-type": "application/json",
          "accept": "application/json",
          "Authorization": token,
        },
        dataSrc: function ( json ) {
          onCompleteAjax()
          return json.data;
        },
      },
      "order" : arr_order,
      // deferLoading: 2,
      "columns" : arr_column,
      "columnDefs" : arr_column_def,
    })
    return datatable
  }

  async request(url, method = 'get', data = {}, with_modal = true, onUploadProgress = () => {}){
    try{
      axios.defaults.headers.common['Accept'] = 'application/json'

      var context = this
      var header = {
        "Content-Type": "application/json",
        "Accept": "application/json",
        "Type": "web",
      }
      var token = await window.localStorage.getItem('token')
      if(url.includes(this.host2))
        header['Authorization'] = this.auth
      else if(token != null && token != '')
        header['Authorization'] = token


      var response
      if(method === 'get'){
        for(let x in data)
          url += (url.includes('?') ? '&' : '?') + x + "=" + JSON.stringify(data[x])

        response = await axios.get(url, {
          headers: header,
        })
        .catch(function (error) {
          if(error.response.status == 401){
            context.show_error("Token not Found! Please login or register at web first")
            window.location.href = context.landing_host + "?type=logout"
          }
          else if (error.response) {
            context.show_error(JSON.stringify(error.response.data))
          }
        })
      }
      else if(method === 'post')
        response = await axios.post(url, data, {
          headers: header,
          onUploadProgress
        })
        .catch(function (error) {
          if(error.response.status == 401){
            context.show_error("Token not Found! Please login or register at web first")
            window.location.href = context.landing_host + "?type=logout"
          }
          else if (error.response) {
            context.show_error(JSON.stringify(error.response.data))
          }
        })
      else if(method === 'put')
        response = await axios.put(url, data, {
          headers: header,
          onUploadProgress
        })
        .catch(function (error) {
          if(error.response.status == 401){
            context.show_error("Token not Found! Please login or register at web first")
            window.location.href = context.landing_host + "?type=logout"
          }
          else if (error.response) {
            context.show_error(JSON.stringify(error.response.data))
          }
        })
      else if(method === 'delete')
        response = await axios.delete(url, {
          headers: header,
          data: data,
        })
        .catch(function (error) {
          if(error.response.status == 401){
            context.show_error("Token not Found! Please login or register at web first")
            window.location.href = context.landing_host + "?type=logout"
          }
          else if (error.response) {
            context.show_error(JSON.stringify(error.response.data))
          }
        })

      if(with_modal){
        setTimeout(() => {
        }, 500)
      }

      return response.data
    } catch(error){
      // console.log(error.response.data)
      return null
    }
  }

  validate_email(email){
    return String(email)
    .toLowerCase()
    .match(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    )
  }

  async remove_action(url){
    if(confirm('Are you sure?')){
      $('#please_wait_modal').modal('show')
      var response = await this.request(url)

      setTimeout(() => {
        $('#please_wait_modal').modal('hide')
      }, 500)
      if(response != null){
        if(response.status === "success"){
          return true
        }
        else
          this.base.show_error(response.message)
      }
      else
        this.base.show_error(this.$t('server_error'))
    }
    return false
  }

  check_phone_format(data){
    return data.length > 0 && data[data.length - 1].match(/^[\d\s]+$/g) == null ? data.substring(0, data.length - 1) : data
  }

  str_to_double(data, default_value = '0'){
    var value
    if(data != '')
      value = parseFloat(data.replace(/\./g,'').replace(/,/g,'.'))
    else
      value = default_value
    return value
  }

  responsive_scroll_threshold(value, additional_margin = 0){
    if(window.screen.width < 720)
      value = value * (12 / 16) + additional_margin
    else if(window.screen.width >= 720 && window.screen.width < 1024)
      value = value * (8 / 16)
    // console.log(value)
    return value
  }

  check_start_animation(scrollY, flag, arr_factor, scroll_threshold = 0, additional_margin = 0){
    var temp = true
    for(let factor of arr_factor){
      if(!factor){
        temp = false
        break
      }
    }

    return (flag || (!flag && scrollY >= this.responsive_scroll_threshold(scroll_threshold, additional_margin))) && temp
  }

  phone_validation(data, max_length = 12){
    data = String(this.str_to_double(data, ''))
    if(isNaN(data))
      data = '0'
    if(data.charAt(0) === '0')
      data = data.slice(1)
    if(max_length > 0 && data.length > max_length)
      data = data.substring(0, max_length)
    return data
  }

  to_currency_format(data, max_number = 100000000, max_comma_length = 2){
    var value = data

    if(value[value.length - 1] !== ","){
      var is_include_comma = false
      var is_convert_double = true
      var index_comma = 0
      for(let x = 0; x < value.length; x++){
        if(value[x] === ","){
          is_include_comma = true
          index_comma = x
        }
        else if(is_include_comma && x == value.length - 1 && value[x] === "0")
          is_convert_double = false
      }

      if(is_include_comma){
        is_convert_double = value.length - index_comma > max_comma_length && value[value.length - 2] !== "0"
        value = value.substring(0, index_comma + 1 + max_comma_length)
      }


      if(is_convert_double){
        value = this.str_to_double(value)
        if(isNaN(value))
          value = 0
        if(value > max_number)
          value = max_number
      }
    }

    return value.toLocaleString(this.locale_string)
  }

  toDataURL(url, callback) {
    var xhr = new XMLHttpRequest();
    xhr.onload = function() {
      var reader = new FileReader();
      reader.onloadend = function() {
        callback(reader.result);
      }
      reader.readAsDataURL(xhr.response);
    };
    xhr.open('GET', url);
    xhr.responseType = 'blob';
    xhr.send();
  }

  show_error(message = ""){
    // showSnackBar({
    //   message: message,
    //   textColor: '#FFF',      // message text color
    //   position: 'top',  // enum(top/bottom).
    //   confirmText: '', // button text.
    //   duration: 2000,   // (in ms), duartion for which snackbar is visible.
    // })
    alert(message)
    // console.log(message)
    // this.$toasted.show(message)
    // sessionStorage.removeItem('message')
  }
}
