<template>
  <div class="">
    <CompetitionHeader :competition="registration_data.competition" v-if="registration_data.competition != null"/>
    <div>

      <div>
        <p class="m-0">Do you want to input Coach Registration?</p>
        <div class="form-check form-check-inline">
          <input class="form-check-input" type="radio" id="questionCoachYes" :value="true" v-model="is_add">
          <label class="form-check-label" for="questionCoachYes">
            Yes
          </label>
        </div>

        <div class="form-check form-check-inline">
          <input class="form-check-input" type="radio" id="questionCoachNo" :value="false" v-model="is_add">
          <label class="form-check-label" for="questionCoachNo">
            No
          </label>
        </div>
      </div>

      <div v-show="is_add">
        <div class="row">
          <div class="col-12 col-md-6 mb-5" v-for="(coach, index) in arr_coach" :key="index">
            <div class="d-flex align-items-center justify-content-between">
              <div class="d-flex align-items-center">
                <div class="card m-0">
                  <div class="card-body">
                    <p class="m-0">{{ index + 1 }}</p>
                  </div>
                </div>
                <p class="m-0 ml-3">Coach Information</p>
              </div>
              <button class="btn btn-danger" v-show="index > 0" @click="onRemoveCoach(index)">Remove</button>
            </div>
            <table class="w-100">
              <tr>
                <td><p class="m-0 mb-3">Position</p></td>
                <td>
                  <v-select @search="onPositionSearch" v-model="coach.coach_position" :options="arr_position" class="mb-3"/>
                </td>
              </tr>
              <tr>
                <td><p class="m-0 mb-3">Coach Name</p></td>
                <td><input type="text" v-model="coach.name" class="form-control mb-3"></td>
              </tr>
              <tr>
                <td><p class="m-0 mb-3">Gender</p></td>
                <td>
                  <select v-model="coach.gender" class="form-control mb-3">
                    <option value="">Select Gender</option>
                    <option value="1">Male</option>
                    <option value="0">Female</option>
                  </select>
                </td>
              </tr>
              <tr>
                <td><p class="m-0 mb-3">Birth Date</p></td>
                <td><date-picker v-model="coach.birth_date" type="date" :default-value="momentNow.toDate()" value-type="format" input-class="form-control mb-3" :format="base.date_format" :disabled-date="disableBirthDate"/></td>
              </tr>
              <tr>
                <td><p class="m-0 mb-3">Image</p></td>
                <td>
                  <CustomImagePicker
                    :image_data1="coach.image_data"
                    @onResponse="(image_data) => onImageResponse(image_data, index)"/>
                </td>
              </tr>
            </table>
          </div>
        </div>
        <div class="mt-3">
          <button class="btn btn-outline-primary w-100" @click="onAddCoach">Add Coach</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import $ from 'jquery'
import Base from '@/utils/base';
import DatePicker from 'vue2-datepicker';
import momentTZ from 'moment-timezone';
import 'vue2-datepicker/index.css';

import CompetitionHeader from '@/pages/registration/component/competition_header';
import CustomImagePicker from '@/layout/custom_imagepicker';

export default {
  components: {
    'CompetitionHeader': CompetitionHeader,
    'CustomImagePicker': CustomImagePicker,
    DatePicker,
  },
  props: ['registration_data', 'before_index', 'index', 'is_submit', 'selected_tab', ],
  data(){
    return{
      base: null,
      arr_position: [],
      arr_coach: [],
      momentNow: null,
      is_add: false,
    }
  },
  watch: {
    before_index(val){
      if(val == this.index)
        this.validation()
    },
    registration_data(val){
      if(val.team_category != null){
        this.fill_data()
      }
    },
    selected_tab(val){
      if(val == 2)
        this.onPositionSearch('')
    },
  },
  mounted(){
    this.base = new Base()
    this.momentNow = momentTZ().subtract(this.base.min_age, 'y')
		this.fill_data()
  },
  methods: {
		fill_data(){
			var arr_coach = []
			this.is_add = this.registration_data.arr_coach != null && this.registration_data.arr_coach.length > 0
			if(this.registration_data.arr_coach == null || this.registration_data.arr_coach.length == 0){
				for(let x = 0; x < 1; x++)
					arr_coach.push({
						name: '',
						gender: 1,
						birth_date: this.momentNow.format(this.base.date_format),
						image_data: {},
						coach_position: {},
					})
			}
			else{
				for(let coach of this.registration_data.arr_coach){
					coach.coach_position.label = coach.coach_position.name
					coach.birth_date = momentTZ(coach.birth_date, this.base.locale_timezone).format(this.base.date_format)
				}
				arr_coach = this.registration_data.arr_coach
			}
			this.arr_coach = arr_coach
		},
    async onPositionSearch(search){
      var response = await this.base.request(this.base.url_api + "/coach-position/all?category_sport_id=" + this.registration_data.competition.category_sport.id + "&search=" + search)

      if(response != null){
        if(response.status === "success"){
          for(let position of response.data){
            position.label = position.name
          }
          this.arr_position = response.data
        }
        // else
        //   this.base.show_error(response.message)
      }
      // else
      //   console.log(this.$t('server_error'))
    },
    disableBirthDate(date){
      return momentTZ(date).isSameOrAfter(momentTZ().subtract(this.base.min_age, 'y'))
    },
    onRemoveCoach(index){
      this.arr_coach.splice(index, 1)
    },
    onAddCoach(){
      this.arr_coach.push({
        name: '',
        gender: 1,
        birth_date: '',
        image_data: {},
        coach_position: {},
      })
    },
    onImageResponse(image_data, index){
      var coach = this.arr_coach[index]
      coach.image_data = image_data
      this.$set(this.arr_coach, index, coach)
    },
    async validation(){
      var message = ""
      if(this.is_add){
        for(let x in this.arr_coach){
          if(this.arr_coach[x].name == null || this.arr_coach[x].name == "")
            message = "Name Coach " + (x + 1) + " is Empty"
          else if(this.arr_coach[x].birth_date == null || this.arr_coach[x].birth_date == "")
            message = "Birth Date Coach " + (x + 1) + " is Empty"
          else if(this.arr_coach[x].coach_position == null || this.arr_coach[x].coach_position.id == null)
            message = "Coach Position Coach " + (x + 1) + " is Empty"
          else if(this.arr_coach[x].image_data.image_display == null)
            message = "Image Coach " + (x + 1) + " is Empty"

          if(message != "")
            break;
        }

        if(message == ''){
          $('#please_wait_modal').modal('show')
          var response = await this.base.request(this.base.url_api + "/event/registration/coach/edit/array", 'post', {
            arr_coach: this.arr_coach,
            registration_event_id: this.registration_data.id,
          })
          setTimeout(() => {
            $('#please_wait_modal').modal('hide')
          }, 500)

          if (response != null) {
            if (response.status != "success")
              message = response.message
              // this.base.show_error(response.message)
          }
        }
      }

      if(message == "" && response != null){
        for(let coach of response.registration_event.coach){
          coach.image_data = {
            image_display: coach.url_image
          }
        }
      }
      this.$emit('onValidation', message, message == '' ? {
        arr_coach: response != null ? response.registration_event.coach : [],
      } : {})
    },
  }
}
</script>

<style lang="scss">
</style>
