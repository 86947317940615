<template>
  <div class="">
    <div class="card">
      <div class="card-body mb-0">
        <table class="table" ref="table">
          <thead>
            <tr>
              <th class="border-b-2 dark:border-dark-5 whitespace-nowrap">#</th>
              <th class="border-b-2 dark:border-dark-5 whitespace-nowrap">Category Sport</th>
              <th class="border-b-2 dark:border-dark-5 whitespace-nowrap">Category</th>
              <th class="border-b-2 dark:border-dark-5 whitespace-nowrap">Team Name</th>
              <th class="border-b-2 dark:border-dark-5 whitespace-nowrap">Total Price</th>
              <th class="border-b-2 dark:border-dark-5 whitespace-nowrap">Created Date</th>
              <th class="border-b-2 dark:border-dark-5 whitespace-nowrap">Expired Date</th>
              <th class="border-b-2 dark:border-dark-5 whitespace-nowrap">Status</th>
              <th class="border-b-2 dark:border-dark-5 whitespace-nowrap">Action</th>
            </tr>
          </thead>
        </table>
      </div><!--end card-body-->
    </div>
  </div>
</template>

<script>
import $ from 'jquery'
import Base from '@/utils/base';

export default {
  components: {
  },
  data(){
    return{
      base: null,
      arr_competition: [],
      selected_competition: {},
      scrollY: 0,
      datatable: null,
    }
  },
  mounted(){
    this.base = new Base()
    var context = this

    this.base.save_current_page('Active Order', true, 'mdi mdi-home')
    setTimeout(() => {
      context.datatable = context.base.init_datatable(context.datatable, context.$refs.table, context.base.host + '/api/order', [[0, "desc"]], [
        {"data" : "id",},
        {"data" : "category_sport_name", name: "category_sport.name"},
        {"data" : "event_category_sport_category_name", name: "event_category_sport_category.name"},
        {"data" : "team_name", name: "registration_event.team_name"},
        {"data" : "total_price_format", name: "order.total_price"},
        {"data" : "created_at_format", name: "order.created_at"},
        {"data" : "expired_at_format", name: "order.payment_expired_at"},
        {"data" : "status_format", name: "order.status"},
        {"data" : "action", "orderable" : false},
      ], [
        {
          targets: -1,
          data: null,
          sorting: false,
          render: function(data, type, row, meta) {
            var str = ""
            str += '<div style="width: 150px">'
              str += `<a class="btn btn-primary" href="/order/detail?id=${row.id}">Detail</a>`
              if(row.status == 'wait_payment' && row.url_payment != null)
                str += `<a target="_blank" href="${row.url_payment}" class="btn btn-primary ml-3">Pay</a>`
              if((row.status == 'canceled' || row.status == 'wait_payment') && row.reorder_order_id == null)
                str += `<button class="btn btn-primary ml-3 reorder" row='${JSON.stringify(row)}'>Reorder</button>`
            str += '</div>'
            return str
          },
        },
      ], [], function() {
        setTimeout(() => {
          $('.reorder').click(function() {
            var row = JSON.parse($(this).attr('row'))

            window.location.href = "/registration/reorder?id=" + row.registration_event_id + '&order_id=' + row.id
          })
        }, 100)
      })
    }, this.base.wait_datatable_time)
  },
  methods: {
    
  }
}
</script>

<style lang="scss">
</style>
