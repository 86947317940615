<template>
  <div class="">
    <div class="card">
      <div class="card-body mb-0">
        <div class="d-flex justify-content-between">
          <button class="btn btn-outline-primary" @click="onCancelClicked">Back</button>
        </div>
        
        <table class="table mt-3" ref="table">
          <thead>
            <tr>
              <th class="border-b-2 dark:border-dark-5 whitespace-nowrap">#</th>
              <th class="border-b-2 dark:border-dark-5 whitespace-nowrap">Name</th>
              <th class="border-b-2 dark:border-dark-5 whitespace-nowrap">Date</th>
              <th class="border-b-2 dark:border-dark-5 whitespace-nowrap">Home Team</th>
              <th class="border-b-2 dark:border-dark-5 whitespace-nowrap">Away Team</th>
              <th class="border-b-2 dark:border-dark-5 whitespace-nowrap">Home Score</th>
              <th class="border-b-2 dark:border-dark-5 whitespace-nowrap">Away Score</th>
              <th class="border-b-2 dark:border-dark-5 whitespace-nowrap">Status</th>
              <th class="border-b-2 dark:border-dark-5 whitespace-nowrap">Action</th>
            </tr>
          </thead>
        </table>
      </div><!--end card-body-->
    </div>
  </div>
</template>

<script>
import $ from 'jquery'

import Base from '@/utils/base';

export default {
  components: {
  },
  data(){
    return{
      base: null,
      arr_competition: [],
      selected_competition: {},
      scrollY: 0,
      datatable: null,
    }
  },
  mounted(){
    this.base = new Base()
    var context = this

    this.get_registration()
    setTimeout(() => {
      context.datatable = context.base.init_datatable(context.datatable, context.$refs.table, context.base.host + `/api/match?registration_event_id=${this.$route.query.registration_event_id}&rel_type=simple`, [[2, "asc"]], [
        {"data" : "id",},
        {"data" : "name", name: "match.name"},
        {"data" : "date_format", name: "match.date"},
        {"data" : "registration_event1_team_name", name: "registration_event1.team_name"},
        {"data" : "registration_event2_team_name", name: "registration_event2.team_name"},
        {"data" : "group_member1_score", name: "match.group_member1_score"},
        {"data" : "group_member2_score", name: "match.group_member2_score"},
        {"data" : "status_format", name: "match.status"},
        {"data" : "action", "orderable" : false},
      ], [
        {
          targets: -1,
          data: null,
          sorting: false,
          render: function(data, type, row, meta) {
            var str = ""
            str += '<div style="width: 150px">'
              str += `<a class="btn btn-primary" href="/match/detail?id=${row.id}">Detail</a>`
            str += '</div>'
            return str
          },
        },
      ])
      
      
      
    }, this.base.wait_datatable_time)

    
  },
  methods: {
    onCancelClicked(){
      this.base.back_page()
    },
    async get_registration(){
      var response = await this.base.request(this.base.url_api + "/event/registration?id=" + this.$route.query.registration_event_id)
    
      if(response != null){
        if(response.status === "success"){
          this.base.save_current_page('Active Match ' + response.data.team_name)
        }
        else
          this.base.show_error(response.message)
      }
      else
        console.log(this.$t('server_error'))
    },
  }
}
</script>

<style lang="scss">
</style>
